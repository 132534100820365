<template>
  <div class="maintenace-table">
    <ag-grid-vue
      :rowData="rowData"
      style="width: 100%; height: 400px;"
      class="ag-theme-alpine"
      :gridOptions="gridOptions"
      @grid-ready="onGridReady"
      :columnDefs="columnDefs"
      :rowSelection="rowSelection"
      :setQuickFilter="updateSearchQuery"
      suppressDragLeaveHidesColumns
      @selection-changed="onSelectionChanged"
      suppressMovableColumns
      checkboxSelection="true"
      @sort-changed="onSortChange"
      :singleClickEdit="true"
      :stopEditingWhenCellsLoseFocus="true"
      @bodyScroll="hideEdit"
    >
      <!-- :rowData="filteredTableData" -->
    </ag-grid-vue>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import moment from "moment";

export default {
  components: {
    AgGridVue,
  },
  props: {
    tableData: { type: Array, required: true },
    rowSelection: { required: false, default: "multiple" },
  },
  data() {
    return {
      id_company: "",
      columnDefs: null,
      gridApi: null,
      gridColumnApi: null,
      gridOptions: {
        rowHeight: 50,
        defaultColDef: {
          resizable: true,
        },
      },
    };
  },
  beforeMount() {
    this.columnDefs = [
      {
        headerName: this.$t("count.order"),
        field: "index",
        // headerCheckboxSelection:
        //   this.rowSelection === "multiple" ? true : false,
        // headerCheckboxSelectionFilteredOnly: true,
        // checkboxSelection: this.rowSelection !== "none" ? true : false,
        maxWidth: 110,
        suppressSizeToFit: true,
        cellClass: "text-center",
      },
      {
        headerName: this.$t('maintenance_report.date/time'),
        field: "request_at",
        sortable: true,
        suppressSizeToFit: true,
      },
      {
        headerName: this.$t('maintenance_report.request-user-name'),
        field: "request_user_name",
        sortable: true,
        suppressSizeToFit: true,
      },
      {
        headerName: this.$t('maintenance_report.activity'),
        field: "action_type",
        sortable: true,
        suppressSizeToFit: true,
        cellRenderer: (params) => {
          if (params.value == "create") {
            return this.$t("maintenance_report.create-maintain");
          } else if (params.value == "detail_change") {
            return this.$t("maintenance_report.change-detail");
          } else if (params.value == "priority_change") {
            return this.$t("maintenance_report.change-urgency");
          } else if (params.value == "status_change") {
            return this.$t("maintenance_report.change-status");
          } else if (params.value == "reset") {
            return this.$t("maintenance_report.reset");
          } else if (params.value == "technician_change") {
            return this.$t("maintenance_report.assigning");
          } else return "-";
        },
      },
      {
        headerName: this.$t("maintenance_report.from"),
        field: "old_data",
        sortable: true,
        suppressSizeToFit: true,
        cellRenderer: (params) => {
          if (params.value == "pending") {
            return this.$t("maintenance_report.pending");
          } else if (params.value == "in_progress") {
            return this.$t("maintenance_report.in_progress");
          } else if (params.value == "complete") {
            return this.$t("maintenance_report.complete");
          } else if (params.value == "reject") {
            return this.$t("maintenance_report.reject");
          } else if (params.value == "cancel") {
            return this.$t("maintenance_report.cancel");
          } else if (params.value == "other") {
            return this.$t("maintenance_report.other");
          } else if (params.value == "high") {
            return this.$t("maintenance_report.high");
          } else if (params.value == "medium") {
            return this.$t("maintenance_report.medium");
          } else if (params.value == "low") {
            return this.$t("maintenance_report.low");
          } else if (params.value == null) {
            return "-";
          } else return params.value;
        },
      },
      {
        headerName: this.$t("maintenance_report.to-date"),
        field: "new_data",
        sortable: true,
        suppressSizeToFit: true,
        cellRenderer: (params) => {
          if (params.value == "pending") {
            return this.$t("maintenance_report.pending");
          } else if (params.value == "in_progress") {
            return this.$t("maintenance_report.in_progress");
          } else if (params.value == "complete") {
            return this.$t("maintenance_report.complete");
          } else if (params.value == "reject") {
            return this.$t("maintenance_report.reject");
          } else if (params.value == "cancel") {
            return this.$t("maintenance_report.cancel");
          } else if (params.value == "other") {
            return this.$t("maintenance_report.other");
          } else if (params.value == "high") {
            return this.$t("maintenance_report.high");
          } else if (params.value == "medium") {
            return this.$t("maintenance_report.medium");
          } else if (params.value == "low") {
            return this.$t("maintenance_report.low");
          } else if (params.value == null) {
            return "-";
          } else return params.value;
        },
      },
      {
        headerName: this.$t("maintenance_report.assetID"),
        field: "id_asset",
        sortable: true,
        suppressSizeToFit: true,
      },
      {
        headerName: this.$t("asset.as_name"),
        field: "name_asset",
        sortable: true,
        suppressSizeToFit: true,
      },
      {
        headerName: this.$t("maintenance_report.maintain-ref"),
        field: "maintain_ref",
        sortable: true,
        suppressSizeToFit: true,
        cellRenderer: (params) => {
          if (params.vlaue === null) {
            return "-";
          } else {
            return params.value;
          }
        },
      },
    ];
  },
  async mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
  },
  watch: {
    // searchText() {
    //   this.updateSearchQuery(this.searchText);
    // },
  },
  computed: {
    rowData() {
      return this.tableData;
    },
  },
  methods: {
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
      this.gridApi.refreshCells();
    },
    hideEdit() {
      this.gridApi.stopEditing();
    },
    onSortChange() {
      const colState = this.gridColumnApi.getColumnState();
      const sortState = colState
        .filter(function(s) {
          return s.sort != null;
        })
        .map(function(s) {
          return {
            colId: s.colId,
            sort: s.sort,
            sortIndex: s.sortIndex,
          };
        });
      // console.log(sortState);
    },

    onGridReady() {
      this.gridApi = this.gridOptions.api;
      this.gridColumnApi = this.gridOptions.columnApi;
    },

    getSelectedRowData() {
      let selectedNodes = this.gridApi.getSelectedNodes();
      let selectedData = selectedNodes.map((node) =>
        this.assetData.find(
          (asset) => asset.id_fixed_asset === node.data.id_fixed_asset
        )
      );
      return selectedData;
    },

    onSelectionChanged(event) {
      const selectedNodes = event.api.getSelectedNodes();
      const selectedData = selectedNodes.map((node) => {
        return node.data;
      });
      this.$emit("selected-assets", selectedData);
    },
  },
};
</script>

<style scoped lang="scss">
.maintenace-table {
  ::v-deep .ag-header-row-column {
    background: transparent linear-gradient(180deg, #007afe 0%, #013f81 100%);
    color: white;
  }
  ::v-deep .ag-row {
    z-index: 0;
    &.ag-row-focus {
      z-index: 1;
    }
  }

  ::v-deep .ag-popup-editor {
    background-color: #fff;
    padding: 0.5rem;
    border: 1px solid #d2d2d2;
    border-radius: 10px;
  }
}
</style>
